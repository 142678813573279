<template>
  <div>
    <b-row>
      <b-col>
        <component
          :is="primaryAddressInformation[field].type"
          v-for="field in ['street', 'city', 'state_id', 'country_id', ]"
          :key="field"
          :ref="`${field}_ref`"
          v-model="primaryAddressInformationForm[field]"
          md="6"
          v-bind="getProps(field)"
        />
      </b-col>
      <b-col>
        <component
          :is="primaryAddressInformation[field].type"
          v-for="field in ['line2','reference','zip']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="primaryAddressInformationForm[field]"
          md="6"
          v-bind="getProps(field)"
        />
<!--        <label :class="isFormTriggered ? 'font-weight-bolder': ''">{{ $t('Address Type') }}</label>-->
        <label class="font-weight-bolder">{{ $t('Address Type') }}</label>
        <component
          :is="primaryAddressInformation[field].type"
          v-for="field in ['account_holder', 'billing', 'shipping']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="primaryAddressInformationForm[field]"
          md="6"
          v-bind="getProps(field)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton, BCol, BForm, BRow, BFormRadio, BModal, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import config from '../../customConfig'
import lFileUpload from '../LFileUploadAttachments.vue'

export default {
  name: 'AddPrimaryAddress',
  components: {
    ErrorAlert,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BFormRadio,
    BModal,
    BFormCheckbox,
    lFileUpload,
  },
  computed: {
    primaryAddressInformationForm() {
      return this.$store.state[this.MODULE_NAME].primaryAddressInformationForm
    },
    isFormTriggered() {
      return this.primaryAddressInformationForm.street !== ''
    },
  },
  methods: {
    getProps(fieldName) {
      const props = {
        is: this.primaryAddressInformation[fieldName].type,
        field: { ...this.primaryAddressInformation[fieldName] },
        name: fieldName,
      }
      if (fieldName === 'reference') {
        props.field = this.primaryAddressInformation[fieldName]
      }
      if (fieldName === 'line2') {
        props.field = this.primaryAddressInformation[fieldName]
      }
      if (fieldName === 'state_id') {
        props.parentValue = this.primaryAddressInformationForm.country_id?.id
        props.token = this.$route.query.token
      }
      return props
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const MODULE_NAME_CLONE = 'cloneData'

    const { primaryAddressInformation } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      primaryAddressInformation,
    }
  },
}
</script>
