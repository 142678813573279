<template>
  <div class="l-file-upload">
<!--    <Can-->
<!--      :i="isEditable.action"-->
<!--      :a="isEditable.subject"-->
<!--    >-->
      <div
        class="l-file-upload__dragzone"
        @dragover.prevent
        @click="clickFileInput"
        @drop.prevent="dropped"
      >
        <p
          class="l-file-upload__dragzone-text"
        >
          Drop Files Here to Upload
        </p>

        <input
          v-show="false"
          ref="refLFileUploadInput"
          :accept="formattedAcceptProps"
          type="file"
          :disabled="doDisabled"
          @input="fileInputChanged"
        >
      </div>
<!--    </Can>-->
  </div>
</template>
<script>
export default {
  props: {
    isEditable: {
      type: Object,
      default: { action: 'manage', subject: 'all' },
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    doDisabled: {
      type: Boolean,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      value: {},
    }
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray
    },
  },
  methods: {
    dropped(e) {
      this.$emit('input', this.onlyAcceptableFiles(e.dataTransfer.files))
    },
    onlyAcceptableFiles(filesArray) {
      // todo add check for it
      return filesArray
    },
    fileInputChanged(e) {
      this.$emit('input', e.target.files)
    },
    clearInputValue() {
      this.$refs.refLFileUploadInput.value = null
    },
    clickFileInput() {
      this.$refs.refLFileUploadInput.click()
    },
  },
}
</script>
<style lang="scss">
.l-file-upload {

  &__dragzone {
    height: 116px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #DDE3E7;
    border-radius: 5px;

    cursor: pointer;
  }

  &__dragzone-text {
    margin: auto;
  }
}
</style>
