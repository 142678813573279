import {
  CHECKBOX_LEF,
  L_ACTIVE_CHECK_LF_BOX,
  L_DATE_BIRTH_MASK_INPUT,
  L_PHONE_MASK_INPUT,
  L_TAGS_INPUT,
  SELECT_CHILD_INPUT,
  SELECT_INPUT,
  TEXT_INPUT,
  TEXT_INPUT_VALID,
  TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'
import router from '@/router'

export default function config() {
  const ACCESS_ABILITY_FOR_ACCOUNTS = { action: 'change', subject: 'Accounts' }
  const PAYMENT_OPTIONS_REQUIRED_MESSAGE = 'This checkboxes is required'
  // Table Handlers
  const tableColumns = [
    {
      key: 'batchdelete',
      label: '',
      thStyle: { width: '30px' },
    },
    {
      key: 'cust_name_dba',
      label: 'Company',
      isSortable: true,
    },
    {
      key: 'default_contact',
      label: 'Contact',
      isSortable: true,
    },
    {
      key: 'phone_one',
      label: 'Phone',
      isSortable: true,
    },
    {
      key: 'last_order',
      label: 'Last Order',
    },
    {
      key: 'due',
      label: 'Due',
    },
    {
      key: 'status',
      label: 'Status',
      isSortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      thStyle: { width: '280px' },
    },
  ]
  const trashTableColumns = [
    {
      key: 'batchdelete',
      label: '',
      thStyle: { width: '30px' },
    },
    {
      key: 'cust_name_dba',
      label: 'Company',
    },
    // {
    //   key: 'customer_id_3rd_party_payment_option',
    //   label: '3rd Party Payment',
    // },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'actions',
      label: '',
    },
  ]
  const suggestionTableColumns = [
    {
      key: 'cust_name_dba',
      label: 'Company',
    },
    {
      key: 'first_name',
      label: 'Default Contact',
      getValue: (value, data) => `${data.default_contact?.first_name ? data.default_contact.first_name : ''} ${data.default_contact?.last_name ? data.default_contact.last_name : ''}`,
    },
    {
      key: 'city',
      label: 'Address',
      getValue: (value, data) => `${data.default_address?.city ? data.default_address.city : ''} ${data.default_address?.street ? data.default_address.street : ''}`,
    },
  ]
  const primaryContactInformation = {
    // cust_name_dba: {
    //   type: TEXT_INPUT_VALID,
    //   label: 'Business Name / Doing-Business-As Name',
    //   // rules: 'required',
    //   paramKey: 'name',
    //   actionName: 'getCustomerByName',
    //   placeholder: 'Type...',
    // },
    first_name: {
      type: TEXT_INPUT,
      label: 'First Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    last_name: {
      type: TEXT_INPUT,
      label: 'Last Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    middle_name: {
      type: TEXT_INPUT,
      label: 'Middle Name',
      placeholder: 'Type...',
    },
    birthday: {
      type: L_DATE_BIRTH_MASK_INPUT,
      label: 'Date of Birth',
    },
    phone_one: {
      type: L_PHONE_MASK_INPUT,
      label: 'Phone Number',
      // options: {
      //   options: {
      //     blocks: [0, 3, 3, 4, 6],
      //     delimiter: '(',
      //     delimiters: ['(', ')', '-', ' ext '],
      //     numericOnly: true,
      //   },
      // },
      rules: 'required',
      // placeholder: 'Type...',
    },
    phone_two: {
      type: L_PHONE_MASK_INPUT,
      // options: {
      //   options: {
      //     blocks: [0, 3, 3, 4, 6],
      //     delimiter: '(',
      //     delimiters: ['(', ')', '-', ' ext '],
      //     numericOnly: true,
      //   },
      // },
      // rules: 'required',
      // placeholder: 'Type...',
    },
    phone_three: {
      type: L_PHONE_MASK_INPUT,
      // options: {
      //   options: {
      //     blocks: [0, 3, 3, 4, 6],
      //     delimiter: '(',
      //     delimiters: ['(', ')', '-', ' ext '],
      //     numericOnly: true,
      //   },
      // },
      // rules: 'required',
      // placeholder: 'Type...',
    },
    phone_four: {
      type: L_PHONE_MASK_INPUT,
      // options: {
      //   options: {
      //     blocks: [0, 3, 3, 4, 6],
      //     delimiter: '(',
      //     delimiters: ['(', ')', '-', ' ext '],
      //     numericOnly: true,
      //   },
      // },
      // rules: 'required',
      // placeholder: 'Type...',
    },
    // cell_no: {
    //   type: L_PHONE_MASK_INPUT,
    //   label: 'Phone Number',
    //   // options: {
    //   //   options: {
    //   //     blocks: [0, 3, 3, 4, 6],
    //   //     delimiter: '(',
    //   //     delimiters: ['(', ')', '-', ' ext '],
    //   //     numericOnly: true,
    //   //   },
    //   // },
    //   rules: 'required',
    //   // placeholder: 'Type...',
    // },
    // work_phone_no: {
    //   type: L_PHONE_MASK_INPUT,
    //   label: 'Additional Phone Number',
    //   // options: {
    //   //   options: {
    //   //     blocks: [0, 3, 3, 4, 6],
    //   //     delimiter: '(',
    //   //     delimiters: ['(', ')', '-', ' ext '],
    //   //     numericOnly: true,
    //   //   },
    //   // },
    //   // rules: 'required',
    //   // placeholder: 'Type...',
    // },
    email: {
      type: TEXT_INPUT,
      label: 'Email Address',
      rules: 'required|email',
      placeholder: 'Type...',
    },
    job_title: {
      type: TEXT_INPUT,
      label: 'Title',
      placeholder: 'Type...',
    },
  }
  const primaryAddressInformation = {
    street: {
      type: TEXT_INPUT,
      label: 'Street',
      placeholder: 'Type...',
      // rules: 'required',
    },
    state_id: {
      type: SELECT_CHILD_INPUT,
      label: 'State',
      store: 'stateList',
      // rules: 'required',
    },
    country_id: {
      type: SELECT_INPUT,
      label: 'Country',
      store: 'countryList',
      fParams: { token: router.history.current.query.token },
      fSearchParams: { token: router.history.current.query.token },
      // rules: 'required',
    },
    reference: {
      type: TEXT_INPUT,
      label: 'Reference',
      placeholder: 'Type...',
    },
    city: {
      type: TEXT_INPUT,
      label: 'City',
      placeholder: 'Type...',
      // rules: 'required',
    },
    zip: {
      type: TEXT_INPUT,
      label: 'Zip Code',
      placeholder: 'Type...',
      // rules: 'required',
    },
    line2: {
      type: TEXT_INPUT,
      label: 'Line 2 (Unit, suite, etc...)',
      placeholder: 'Type...',
    },
    billing: {
      type: CHECKBOX_LEF,
      label: 'Billing',
    },
    shipping: {
      type: CHECKBOX_LEF,
      label: 'Shipping',
    },
    account_holder: {
      type: CHECKBOX_LEF,
      label: 'Account Holder',
    },
    can_place_orders: {
      type: CHECKBOX_LEF,
      label: 'Can Place Orders',
    },
  }
  const accountParameters = {
    cust_name_dba: {
      type: TEXT_INPUT_VALID,
      label: 'Business Name / Doing-Business-As Name',
      rules: 'required',
      paramKey: 'name',
      actionName: 'getCustomerByName',
      placeholder: 'Type...',
    },
    is_active: {
      type: L_ACTIVE_CHECK_LF_BOX,
      rules: 'required',
    },
    currency_id: {
      type: SELECT_INPUT,
      label: 'Currency',
      store: 'currencyList',
      rules: 'required',
      fParams: { token: router.history.current.query.token },
      fSearchParams: { token: router.history.current.query.token },
      firstSelect: true,
      options: {
        label: 'iso_currency',
      },
    },
    location_id: {
      type: SELECT_INPUT,
      label: 'Default Location',
      fParams: { token: router.history.current.query.token },
      fSearchParams: { token: router.history.current.query.token },
      store: 'warehouseList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    payment_term_id: {
      type: L_TAGS_INPUT,
      label: 'Payment Term',
      store: 'paymentTermList',
      fParams: { token: router.history.current.query.token },
      fSearchParams: { token: router.history.current.query.token },
      rules: 'required',
    },
    payment_method_id: {
      type: L_TAGS_INPUT,
      label: 'Payment Method',
      store: 'paymentOptionList',
      fParams: { token: router.history.current.query.token },
      fSearchParams: { token: router.history.current.query.token },
      // rules: 'required',
    },
    // price_tier_id: {
    //   type: SELECT_INPUT,
    //   label: 'Price/rate Tier',
    //   fParams: { token: router.history.current.query.token },
    //   fSearchParams: { token: router.history.current.query.token },
    //   store: 'priceTierList',
    //   rules: 'required',
    //   options: {
    //     label: 'label',
    //   },
    // },
    comments: {
      type: TEXTAREA_INPUT,
      label: 'Customer Notes',
      options: {
        rows: '4',
      },
    },
    account_manager_id: {
      type: SELECT_INPUT,
      label: 'Account Manager',
      store: 'accountManagerList',
      // rules: 'required',
      options: {
        'get-option-label': option => `${option.firstname} ${option.lastname}`,
      },
    },
    delivery_type_id: {
      type: SELECT_INPUT,
      label: 'Default Delivery Type',
      store: 'dispatchAndReturnMethodsList',
      rules: 'required',
      fParams: { is_active: 1, token: router.history.current.query.token },
      fSearchParams: { is_active: 1, token: router.history.current.query.token },
      options: {
        label: 'name',
      },
    },
    // tax_rule_id: {
    //   type: SELECT_INPUT,
    //   label: 'Tax Rules',
    //   store: 'taxRuleList',
    //   rules: 'required',
    //   fParams: { is_active: 1 },
    //   fSearchParams: { is_active: 1 },
    //   options: {
    //     label: 'label',
    //   },
    // },
    ein_tax_number: {
      type: TEXT_INPUT,
      label: 'EIN / Tax Number',
      placeholder: 'Type...',
    },
    duns_number: {
      type: TEXT_INPUT,
      label: 'DUNS Number',
      rules: 'numeric',
      placeholder: 'Type...',
    },
    discount: {
      type: TEXT_INPUT,
      label: 'Default Discount',
      rules: 'integer|required',
      placeholder: 'Type...',
    },
    credit_limit: {
      type: TEXT_INPUT,
      label: 'Credit Limit',
      rules: 'integer|required',
      placeholder: 'Type...',
    },
    tags: {
      type: L_TAGS_INPUT,
      fParams: { token: router.history.current.query.token },
      fSearchParams: { token: router.history.current.query.token },
      label: 'Tags',
      store: 'tagsList',
    },
    allow_to_rent_from_subrent: {
      type: CHECKBOX_LEF,
      label: 'Allow to Rent From (sub-rent)',
      rules: 'required',
    },
    allow_to_purchase_from_supplier: {
      type: CHECKBOX_LEF,
      label: 'Allow to Purchase from (supplier)',
      rules: 'required',
    },
    // balance_due: {
    //   type: CHECKBOX_LEF,
    //   label: 'Balance Due',
    //   rules: 'required',
    // },
    pseudonym: {
      type: TEXT_INPUT,
      label: 'Nickname',
      placeholder: 'Type...',
    },
  }
  const moreContactInformation = {
    // job_title: {
    //   type: TEXT_INPUT,
    //   label: 'Title',
    //   placeholder: 'Type...',
    // },
    contact_by_email: {
      type: CHECKBOX_LEF,
      label: 'Email',
    },
    contact_by_phone: {
      type: CHECKBOX_LEF,
      label: 'Phone',
    },
    birthday: {
      type: L_DATE_BIRTH_MASK_INPUT,
      label: 'Date of Birth',
    },
    phone_no: {
      type: L_PHONE_MASK_INPUT,
      label: 'Additional Phone Number',
      // options: {
      //   options: {
      //     blocks: [0, 3, 3, 4, 6],
      //     delimiter: '(',
      //     delimiters: ['(', ')', '-', ' ext '],
      //     numericOnly: true,
      //   },
      // },
      // rules: 'numeric',
      // placeholder: 'Type...',
    },
    // pseudonym: {
    //   type: TEXT_INPUT,
    //   label: 'Nickname',
    //   placeholder: 'Type...',
    // },
    contact_types: {},
  }
  const preferredContactMethod = [
    {
      value: 'email',
      title: 'Email',
    },
    {
      value: 'phone',
      title: 'Phone',
    },
  ]

  // Handlers
  const setObjectOfPhoneMusk = (mask, formName, fieldName) => {
    if (!mask) return

    // eslint-disable-next-line no-param-reassign
    formName[fieldName] = mask
  }

  const getCustomerName = customer => customer.cust_name_dba ?? '—'

  const primaryContactFields = [
    { key: 'action', label: '' },
    { key: 'name', label: 'Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'type', label: 'Type' },
  ]

  const primaryAddressFields = [
    { key: 'action', label: '' },
    { key: 'address', label: 'Address' },
    { key: 'city', label: 'City' },
    { key: 'state', label: 'State' },
    { key: 'type', label: 'Type' },
  ]
  const supplierActionList = [
    {
      key: 'startAQuote',
      label: 'Start a Quote',
    },
    {
      key: 'startAPo',
      label: 'Start a PO',
    },
  ]
  const phoneOptions = [
    {
      key: 'mobile',
      label: 'Cell',
      indexName: 'phone_one',
    },
    {
      key: 'work',
      label: 'Work',
      indexName: 'phone_two',
    },
    {
      key: 'home',
      label: 'Home',
      indexName: 'phone_three',
    },
    {
      key: 'fax',
      label: 'Fax',
      indexName: 'phone_four',
    },
  ]
  return {
    tableColumns,
    accountParameters,
    setObjectOfPhoneMusk,
    moreContactInformation,
    suggestionTableColumns,
    primaryContactInformation,
    primaryAddressInformation,
    getCustomerName,
    primaryContactFields,
    primaryAddressFields,
    preferredContactMethod,
    trashTableColumns,
    supplierActionList,
    ACCESS_ABILITY_FOR_ACCOUNTS,
    PAYMENT_OPTIONS_REQUIRED_MESSAGE,
    phoneOptions,
  }
}
