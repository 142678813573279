<template>
  <sub-menu-component :menu-items="menuItems">
    <portal to="body-top">
      <p class="text-center font-weight-bolder font-large-1">
        {{ $t('Add a Business Entity') }}
      </p>
    </portal>

    <error-alert
      :fields="createFields"
      :error="error"
    />
    <div>
      <form-create-skeleton v-if="loading" />
      <customers-form
        v-else
        ref="customers-form"
        :is-create-form="true"
        @clear-form-default="clearFormDefault"
      />
    </div>
  </sub-menu-component>
</template>
<script>
import mainCustomersMenuItems from '@/views/components/sub-menu/main-sub-menu/home-customers'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
// eslint-disable-next-line import/no-cycle
import CustomersForm from '@/views/main/customers/components/CustomerForm/CustomersForm.vue'
import config from '../customConfig'
import FormCreateSkeleton from '../components/FormCreateSkeleton.vue'

export default {
  name: 'CustomerCreate',
  components: {
    CustomersForm,
    ErrorAlert,
    FormCreateSkeleton,
  },
  data() {
    return {
      error: {},
      defaultSettings: {},
      getDefaultLocation: {},
      defaultCountryId: null,
      datePattern: null,
      birthdayInputPlaceholder: null,
      loading: false,
    }
  },
  computed: {
    wareHouse() {
      return this.$store.state.app.warehouse
    },
    accountParametersForm() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
    moreContactInformationForm() {
      return this.$store.state[this.MODULE_NAME].moreContactInformationForm
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['customers-form']) {
      this.$refs['customers-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.fetchDefaults()
  },
  methods: {
    async fetchDefaults() {
      this.loading = true
      this.$store.commit(`${this.MODULE_NAME}/SET_ACCOUNT_PARAMETERS_FORM`, {
        tags: [],
        payment_term_id: [],
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_PRIMARY_CONTACT_INFORMATION_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_PRIMARY_ADDRESS_INFORMATION_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_MORE_CONTACT_INFORMATION_FORM`, {})

      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_FORM_CLONE`, { tags: [] })
      // await this.$store.dispatch('listModule/organizationData')
      //   .then(res => {
      //     const { data } = res.data
      //     this.defaultCountryId = data.detail?.country
      //   })
      // await this.$store.dispatch(`${this.MODULE_NAME_ORGANIZATION}/get`)
      //   .then(res => {
      //     const { data } = res.data
      //     this.datePattern = data?.date_format.label.split('/')
      //       .map(item => (item[0] === 'y' ? item[0].toUpperCase() : item[0].toLowerCase()))
      //     this.birthdayInputPlaceholder = data?.date_format.label
      //   })

      this.$store.dispatch(`${this.MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME}/getDefaultSettings`)
        .then(res => {
          const { data } = res.data
          this.defaultSettings = data
        })
        .finally(() => {
          const primaryContactInforamtion = {
            // cell_no_object: { countryCode: 'GB', isValid: false },
            // cell_no: null,
            phone_one_type: this.phoneOptions[0],

            phone_two_type: this.phoneOptions[1],

            phone_three_type: this.phoneOptions[2],

            phone_four_type: this.phoneOptions[3],
            datePattern: this.datePattern,
            birthdayInputPlaceholder: this.birthdayInputPlaceholder,
            preferred_contact_method: this.defaultSettings.contact_preferred_contact_method,
          }

          const accauntParametrsData = {
            allow_to_purchase_from_supplier: this.defaultSettings.allow_to_purchase_from_supplier,
            allow_to_rent_from_subrent: this.defaultSettings.allow_to_rent_from_subrent,
            credit_limit: this.defaultSettings.credit_limit,
            currency_id: this.defaultSettings.currency,
            customer_rules_ids: this.defaultSettings.customer_rule_ids,
            delivery_type_id: this.defaultSettings.delivery_type,
            discount: this.defaultSettings.discount,
            payment_options_ids: this.defaultSettings.payment_option_ids,
            payment_term_id: [],
            // price_tier_id: this.defaultSettings.price_tier,
            is_active: this.defaultSettings.status,
            // tax_rule_id: this.defaultSettings.tax_rule,
            location_id: this.wareHouse,
            // account_manager_id: JSON.parse(localStorage.getItem('userData')),
            // customer_id_3rd_party_payment_option: this.accountParametersForm.customer_id_3rd_party_payment_option,
            tags: [],
          }

          const primaryAddressInformationData = {
            street: '',
            country_id: this.defaultCountryId,
            billing: this.defaultSettings.address_type_is_billing,
            shipping: this.defaultSettings.address_type_is_shipping,
            account_holder: this.defaultSettings.address_type_is_account_holder,
          }

          const contactTypes = []
          const primaryContactInformationContactTypes = [
            this.defaultSettings.contact_type_is_billing,
            this.defaultSettings.contact_type_is_can_place_shipping,
            this.defaultSettings.contact_type_is_account_holder,
            this.defaultSettings.contact_type_is_can_place_orders,
          ]

          for (let i = 0; i < primaryContactInformationContactTypes.length; i++) {
            if (primaryContactInformationContactTypes[i]) {
              contactTypes.push(i)
            }
          }

          const moreContactInformationData = {
            is_billing: this.defaultSettings.contact_type_is_billing,
            is_shipping: this.defaultSettings.contact_type_is_can_place_shipping,
            can_place_orders: this.defaultSettings.contact_type_is_can_place_orders,
            is_account_holder: this.defaultSettings.contact_type_is_account_holder,
            // preferred_contact_method: this.defaultSettings.contact_preferred_contact_method,
            // datePattern: this.datePattern,
            // birthdayInputPlaceholder: this.birthdayInputPlaceholder,
            birthday: '',
            phone_no_object: { countryCode: 'US', isValid: false },
            phone_no: null,
          }

          const mergedData = {
            ...accauntParametrsData,
            ...primaryAddressInformationData,
            ...moreContactInformationData,
            ...primaryContactInforamtion,
          }

          this.$store.commit(`${this.MODULE_NAME}/SET_ACCOUNT_PARAMETERS_FORM`, accauntParametrsData)
          this.$store.commit(`${this.MODULE_NAME}/SET_PRIMARY_ADDRESS_INFORMATION_FORM`, primaryAddressInformationData)
          this.$store.commit(`${this.MODULE_NAME}/SET_MORE_CONTACT_INFORMATION_FORM`, moreContactInformationData)
          this.$store.commit(`${this.MODULE_NAME}/SET_PRIMARY_CONTACT_INFORMATION_FORM`, primaryContactInforamtion)

          this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_FORM_CLONE`, mergedData)
          this.$store.commit(`${this.MODULE_NAME}/GET`, {})
          this.loading = false
        })
    },
    clearFormDefault() {
      this.fetchDefaults()
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME = 'settings-customer'
    const MODULE_NAME_ORGANIZATION = 'organization'
    const MODULE_NAME_CLONE = 'cloneData'

    const { menuItems } = mainCustomersMenuItems('home-customers-create', {}, true)
    const {
      primaryAddressInformation, accountParameters, primaryContactInformation, phoneOptions,
    } = config()
    const createFields = {
      primaryAddressInformation,
      accountParameters,
      primaryContactInformation,
    }

    return {
      menuItems,
      MODULE_NAME_CLONE,
      MODULE_SETTINGS_DEFAULT_CUSTOMER_NAME,
      MODULE_NAME,
      createFields,
      phoneOptions,
      MODULE_NAME_ORGANIZATION,
    }
  },
}
</script>
