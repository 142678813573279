<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <template #overlay>
      <div class="d-flex flex-column align-items-center justify-center">
        <h2 class="mb-2">
          {{ $t('Saving...') }}
        </h2>
        <b-spinner variant="secondary" />
      </div>
    </template>
    <div>
      <error-alert
        :fields="fields"
        :error="error"
      />
      <validation-observer
        ref="customerCreateVal"
      >
        <div class="mb-2">
          <div
            v-b-toggle="'add-primary-contact'"
            @click="toggle('add-primary-contact-feather-chevron', 'addPrimaryContact')"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                id="add-primary-contact-feather-chevron"
                icon="LArrowDownIcon"
                class="feather-chevron when-opened mr-1"
                style="transform: rotate(-90deg)"
              />
              <h4 class="font-weight-bolder text-body m-0">
                {{ $t('Add Primary Contact') }}
              </h4>
            </div>
          </div>
          <b-collapse
            :id="'add-primary-contact'"
            ref="add-primary-contact"
            visible
          >
            <div class="mt-1">
              <add-primary-contact />
            </div>
          </b-collapse>
        </div>
        <!--        <div class="mb-2">-->
        <!--          <div-->
        <!--            v-b-toggle="'more-contact-information'"-->
        <!--            @click="toggle('more-contact-information-feather-chevron', 'moreContactToggler')"-->
        <!--          >-->
        <!--            <div class="d-flex align-items-center">-->
        <!--              <feather-icon-->
        <!--                id="more-contact-information-feather-chevron"-->
        <!--                icon="LArrowDownIcon"-->
        <!--                class="feather-chevron when-opened mr-1"-->
        <!--                style="transform: rotate(-90deg)"-->
        <!--              />-->
        <!--              <h4 class="font-weight-bolder text-body m-0">-->
        <!--                {{ $t('More Contact Information') }}-->
        <!--              </h4>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <b-collapse-->
        <!--            :id="'more-contact-information'"-->
        <!--            ref="more-contact-information"-->
        <!--          >-->
        <!--            <div class="mt-1">-->
        <!--              <more-contact-information />-->
        <!--            </div>-->
        <!--          </b-collapse>-->
        <!--        </div>-->
        <div class="mb-2">
          <div
            v-b-toggle="'primary-address-information'"
            @click="toggle('primary-address-information-feather-chevron', 'primaryAddressToggler')"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                id="primary-address-information-feather-chevron"
                icon="LArrowDownIcon"
                class="feather-chevron when-opened mr-1"
                style="transform: rotate(-90deg)"
              />
              <h4 class="font-weight-bolder text-body m-0">
                {{ $t('Add Primary Address') }}
              </h4>
            </div>
          </div>
          <b-collapse
            :id="'primary-address-information'"
            ref="primary-address-information"
            visible
          >
            <div class="mt-1">
              <add-primary-address />
            </div>
          </b-collapse>
        </div>
        <div class="mb-2">
          <div
            v-b-toggle="'business-information'"
            @click="toggle('business-information-feather-chevron', 'businessInformationToggler')"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                id="business-information-feather-chevron"
                icon="LArrowDownIcon"
                class="feather-chevron when-opened mr-1"
                style="transform: rotate(-90deg)"
              />
              <h4 class="font-weight-bolder text-body m-0">
                {{ $t('Business Information') }}
              </h4>
            </div>
          </div>
          <b-collapse
            :id="'business-information'"
            ref="business-information"
            visible
          >
            <div class="mt-1">
              <business-information />
            </div>
          </b-collapse>
        </div>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <!--              <b-button-->
              <!--                v-if="!isFormChanged"-->
              <!--                class="cancelBtn font-medium-1 font-weight-bolder"-->
              <!--                variant="outline-primary"-->
              <!--                @click="$router.push({ name: 'home-customers' })"-->
              <!--              >-->
              <!--                {{ $t('Back to List') }}-->
              <!--              </b-button>-->
              <!--              <b-button-->
              <!--                v-else-->
              <!--                class="cancelBtn font-medium-1 font-weight-bolder"-->
              <!--                variant="outline-primary"-->
              <!--                @click="cancel"-->
              <!--              >-->
              <!--                <feather-icon-->
              <!--                  icon="LCancelIcon"-->
              <!--                  size="16"-->
              <!--                />-->
              <!--                {{ $t('Cancel') }}-->
              <!--              </b-button>-->
            </div>
            <div>
              <!--              <b-button-->
              <!--                variant="secondary"-->
              <!--                class="saveBtn font-medium-1 font-weight-bolder mr-2"-->
              <!--                :disabled="true"-->
              <!--                @click="submit"-->
              <!--              >-->
              <!--                {{ $t('Create a Quote') }}-->
              <!--              </b-button>-->

              <!--              <b-button-->
              <!--                class="saveBtn font-medium-1 font-weight-bolder mr-1"-->
              <!--                :disabled="isCreateForm ? false : !isFormChanged"-->
              <!--              >-->
              <!--                <b-spinner-->
              <!--                  v-if="isLoading"-->
              <!--                  small-->
              <!--                />-->
              <!--                <span v-else>{{ $t('Send Reset Password Link') }}</span>-->
              <!--              </b-button>-->

              <!--              <b-button-->
              <!--                class="saveBtn font-medium-1 font-weight-bolder mr-1"-->
              <!--                :disabled="isCreateForm ? false : !isFormChanged"-->
              <!--              >-->
              <!--                <b-spinner-->
              <!--                  v-if="isLoading"-->
              <!--                  small-->
              <!--                />-->
              <!--                <span v-else>{{ $t('Invite to Create Web Account') }}</span>-->
              <!--              </b-button>-->

              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                @click="submit"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                <span v-else>{{ $t('SAVE') }}</span>
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
      <b-s-warning-modal ref="confirm-modal" />
    </div>
  </b-overlay>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCollapse, BOverlay, BSpinner, VBToggle,
} from 'bootstrap-vue'
import jsonToFormData from '@/libs/jsonToFormData'
import config from '@/views/main/customers/customConfig'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import useJwt from '@/auth/jwt/useJwt'
// import MoreContactInformation from './MoreContactInformation.vue'
import AddPrimaryAddress from './AddPrimaryAddress.vue'
import BusinessInformation from './BusinessInformation.vue'
import AddPrimaryContact from './AddPrimaryContact.vue'

export default {
  name: 'CustomersForm',
  components: {
    BSpinner,
    BOverlay,
    // MoreContactInformation,
    BButton,
    BCollapse,
    ErrorAlert,
    ValidationObserver,
    AddPrimaryAddress,
    BusinessInformation,
    AddPrimaryContact,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attachments: [],
      isLoading: false,
      error: {},
      primaryAddressToggler: false,
      businessInformationToggler: false,
      addPrimaryContact: false,
      onSubmit: false,
    }
  },
  computed: {
    primaryContactInformationForm() {
      return this.$store.state[this.MODULE_NAME].primaryContactInformationForm
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentTermList
    },
    paymentMethodOptionList() {
      return this.$store.state.listModule.paymentOptionList
    },
    primaryAddressInformationForm() {
      return this.$store.state[this.MODULE_NAME].primaryAddressInformationForm
    },
    accountParametersForm() {
      return this.$store.state[this.MODULE_NAME].accountParametersForm
    },
    moreContactInformationForm() {
      return this.$store.state[this.MODULE_NAME].moreContactInformationForm
    },
    customer() {
      return this.$store.state[this.MODULE_NAME].customerForm
    },
    // customerFormClone() {
    //   return this.$store.state[this.MODULE_NAME_CLONE].customerGeneralForm
    // },
    // isFormChanged() {
    //   const combinedForms = {
    //     ...this.accountParametersForm,
    //     ...this.primaryAddressInformationForm,
    //     ...this.moreContactInformationForm,
    //     ...this.primaryContactInformationForm,
    //   }
    //
    //   const currentFormString = JSON.stringify(combinedForms)
    //   const isChanged = currentFormString !== this.customerFormClone
    //
    //   useJwt.setFormChangesOnForm(isChanged)
    //
    //   return isChanged
    // },
  },
  created() {
    useJwt.setFormChangesOnForm(false)
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.$refs.customerCreateVal.reset()
  //   }, 0)
  // },
  methods: {
    firstLetUppercase(str) {
      return str[0].toUpperCase() + str.slice(1)
    },
    // showConfirmationModal(next) {
    //   if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    // },
    toggle(id, collapseToggler) {
      this[collapseToggler] = !this[collapseToggler]
      if (this[collapseToggler]) {
        document.getElementById(id).style.transform = 'rotate(0)'
        document.getElementById(id).style.transition = 'all 0.2s ease'
      } else {
        document.getElementById(id).style.transform = 'rotate(-90deg)'
      }
    },
    openCollapseOnErr() {
      const toggleElements = [
        {
          id: 'primary-address-information-feather-chevron',
        },
        {
          id: 'business-information-feather-chevron',
        },
        {
          id: 'add-primary-contact-feather-chevron',
        },
      ]
      toggleElements.forEach(item => {
        document.getElementById(item.id).style.transform = 'rotate(0)'
        document.getElementById(item.id).style.transition = 'all 0.2s ease'
      })
      this.primaryAddressToggler = true
      this.businessInformationToggler = true
      this.addPrimaryContact = true

      this.$refs['business-information'].show = true
      this.$refs['primary-address-information'].show = true
      this.$refs['add-primary-contact'].show = true
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.customerCreateVal.validate()
        .then(success => {
          if (success) {
            const primaryContactInformationData = this.primaryContactInformationForm
            const primaryAddressInformationData = this.primaryAddressInformationForm
            // const moreContactInformationData = this.moreContactInformationForm
            const accountParametersData = this.accountParametersForm

            const paymentTermsIds = this.paymentOptions?.reduce((acc, item) => {
              if (accountParametersData?.payment_term_id?.includes(item.label)) {
                acc.push(item.id)
              }
              return acc
            }, [])

            const paymentMethodIds = this.paymentMethodOptionList?.reduce((acc, item) => {
              if (accountParametersData?.payment_method_id?.includes(item.name)) {
                acc.push(item.id)
              }
              return acc
            }, [])

            if (primaryContactInformationData.phone_one_ext_val) {
              primaryContactInformationData.phone_one_object.phone_ext_val = primaryContactInformationData.phone_one_ext_val
            }

            if (primaryContactInformationData.phone_two_ext_val) {
              primaryContactInformationData.phone_two_object.phone_ext_val = primaryContactInformationData.phone_two_ext_val
            }

            if (primaryContactInformationData.phone_three_ext_val) {
              primaryContactInformationData.phone_three_object.phone_ext_val = primaryContactInformationData.phone_three_ext_val
            }

            if (primaryContactInformationData.phone_four_ext_val) {
              primaryContactInformationData.phone_four_object.phone_ext_val = primaryContactInformationData.phone_four_ext_val
            }

            if (primaryContactInformationData.phone_one_type) {
              primaryContactInformationData.phone_one_object.phone_type_val = primaryContactInformationData.phone_one_type.label
            }

            if (primaryContactInformationData.phone_two_type) {
              primaryContactInformationData.phone_two_object.phone_type_val = primaryContactInformationData.phone_two_type.label
            }

            if (primaryContactInformationData.phone_three_type) {
              primaryContactInformationData.phone_three_object.phone_type_val = primaryContactInformationData.phone_three_type.label
            }

            if (primaryContactInformationData.phone_four_type) {
              primaryContactInformationData.phone_four_object.phone_type_val = primaryContactInformationData.phone_four_type.label
            }

            const primaryContactInformation = this.mappingFields(Object.keys(this.primaryContactInformation), primaryContactInformationData, {
              // attachments: primaryContactInformationData.attachments,
              // pseudonym: moreContactInformationData.pseudonym,
              attachments: primaryContactInformationData.attachments,
              contacts: [
                {
                  first_name: primaryContactInformationData.first_name,
                  last_name: primaryContactInformationData.last_name,
                  middle_name: primaryContactInformationData.middle_name,

                  phone_one: primaryContactInformationData.phone_one_object?.isValid ? primaryContactInformationData.phone_one_object.formattedNumber : null,
                  phone_two: primaryContactInformationData.phone_two_object?.isValid ? primaryContactInformationData.phone_two_object.formattedNumber : null,
                  phone_three: primaryContactInformationData.phone_three_object?.isValid ? primaryContactInformationData.phone_three_object.formattedNumber : null,
                  phone_four: primaryContactInformationData.phone_four_object?.isValid ? primaryContactInformationData.phone_four_object.formattedNumber : null,
                  phone_one_object: primaryContactInformationData.phone_one_object.isValid ? JSON.stringify(primaryContactInformationData.phone_one_object) : null,
                  phone_two_object: primaryContactInformationData.phone_two_object?.isValid ? JSON.stringify(primaryContactInformationData.phone_two_object) : null,
                  phone_three_object: primaryContactInformationData.phone_three_object?.isValid ? JSON.stringify(primaryContactInformationData.phone_three_object) : null,
                  phone_four_object: primaryContactInformationData.phone_four_object?.isValid ? JSON.stringify(primaryContactInformationData.phone_four_object) : null,

                  email: primaryContactInformationData.email,
                  birthday: primaryContactInformationData.birthday,
                  // contact_by_email: moreContactInformationData.contact_by_email ?? false,
                  // contact_by_phone: moreContactInformationData.contact_by_phone ?? false,
                  is_billing: primaryContactInformationData.is_billing ? 1 : 0,
                  is_shipping: primaryContactInformationData.is_shipping ? 1 : 0,
                  can_place_orders: primaryContactInformationData.can_place_orders ? 1 : 0,
                  is_account_holder: primaryContactInformationData.is_account_holder ? 1 : 0,
                  job_title: primaryContactInformationData.job_title,
                  preferred_contact_method: primaryContactInformationData.preferred_contact_method,
                },
              ],
            })
            let primaryAddressInformation = this.mappingFields(Object.keys(this.primaryAddressInformation), primaryAddressInformationData, {
              street: primaryAddressInformationData?.street,
              state_id: primaryAddressInformationData.state_id?.id,
              city: primaryAddressInformationData?.city,
              zip: primaryAddressInformationData?.zip,
              country_id: primaryAddressInformationData.country_id?.id,
              reference: primaryAddressInformationData?.reference,
              line2: primaryAddressInformationData?.line2,
              account_holder: primaryAddressInformationData.account_holder ? 1 : 0,
              billing: primaryAddressInformationData.billing ? 1 : 0,
              shipping: primaryAddressInformationData.shipping ? 1 : 0,
              // can_place_orders: primaryAddressInformationData.can_place_orders ?? false,
            })
            if (this.primaryAddressInformationForm.street === '') {
              primaryAddressInformation = null
            }
            const accountParameters = this.mappingFields(Object.keys(this.accountParameters), accountParametersData, {
              cust_name_dba: accountParametersData.cust_name_dba,
              is_active: accountParametersData.is_active ? 1 : 0,
              currency_id: accountParametersData.currency_id.id,
              payment_term_id: paymentTermsIds,
              payment_method_id: paymentMethodIds,
              // price_tier_id: accountParametersData.price_tier_id.id,
              // tax_rule_id: accountParametersData.tax_rule_id.id,
              pseudonym: accountParametersData.pseudonym,
              // account_manager_id: accountParametersData.account_manager_id?.id ?? null,
              delivery_type_id: accountParametersData.delivery_type_id.id,
              location_id: accountParametersData.location_id.id,
              ein_tax_number: accountParametersData.ein_tax_number,
              duns_number: accountParametersData.duns_number,
              discount: accountParametersData.discount,
              credit_limit: accountParametersData.credit_limit,
              comments: accountParametersData.comments,

              payment_options_id: accountParametersData.payment_options_ids,
              customer_rules_id: accountParametersData.customer_rules_ids,
              allow_to_rent_from_subrent: accountParametersData.allow_to_rent_from_subrent ? 1 : 0,
              allow_to_purchase_from_supplier: accountParametersData.allow_to_purchase_from_supplier ? 1 : 0,
              // customer_id_3rd_party_payment_option: accountParametersData.customer_id_3rd_party_payment_option?.id ?? null,
            })

            const unnecessaryFields = ['birthday', 'cell_no', 'contact_by_email', 'contact_by_phone', 'email', 'first_name', 'last_name', 'job_title', 'middle_name', 'phone_no']

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < unnecessaryFields.length; i++) {
              delete primaryContactInformation[unnecessaryFields[i]]
            }
            if (primaryContactInformation.contacts.birthday) {
              const date = new Date(primaryContactInformation.contacts.birthday)
              primaryContactInformation.contacts.birthday = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
            }

            const customer = {
              ...primaryContactInformation,
              ...accountParameters,
            }
            if (primaryAddressInformation) {
              primaryAddressInformation.is_default = 1
              primaryAddressInformation.is_state = 1

              customer.addresses = [primaryAddressInformation]
            }
            customer.token = this.$route.query.token
            this.isLoading = true
            const contactsFormData = jsonToFormData(customer)
            this.sendNotification(this, contactsFormData, `${this.MODULE_NAME}/updateCustomer`)
              .then(res => {
                this.clear()
                this.$router.replace({
                  name: 'home-customers-view',
                  params: { id: res.data.data?.id },
                })
              })
              .catch(err => {
                this.error = err.response?.data
                this.openCollapseOnErr()
                scrollToError(this, this.$refs.customerCreateVal)
              }).finally(() => {
                this.isLoading = false
                this.onSubmit = false
              })
          } else {
            this.openCollapseOnErr()
            scrollToError(this, this.$refs.customerCreateVal)
          }
        })
    },
    // clear() {
    //   this.$refs.customerCreateVal.reset()
    //   this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    // },
    // cancel() {
    //   this.$emit('clear-form-default')
    //   // this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_FORM_CLONE`, mergedData)
    //
    //   // this.clear()
    //   // this.$router.push({ name: 'home-customers' })
    // },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const MODULE_NAME_DEFAULT = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      primaryContactInformation, accountParameters, primaryAddressInformation, moreContactInformation,
    } = config()
    const fields = { ...primaryContactInformation, ...accountParameters, ...primaryAddressInformation }
    return {
      fields,
      MODULE_NAME,
      MODULE_NAME_DEFAULT,
      MODULE_NAME_CLONE,
      accountParameters,
      moreContactInformation,
      primaryContactInformation,
      primaryAddressInformation,
    }
  },
}
</script>
